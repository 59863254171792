<template>
    <div class="singup">
        <div class="menu-top">
            <div class="logo" @click="returnHomePage()"></div>

            <div class="language">
                <select v-model="language" @change="changeLang()">
                    <option v-for="language in languages" :data="language" :key="language">{{ language }}</option>
                </select>
            </div>
        </div>

        <div class="content" v-if="in_form">
            <div class="information">
                <div class="information-title">{{ $t('signup.information.title') }}</div>
                <div class="information-description">{{ $t('signup.information.description') }}</div>
                <div class="information-image"></div>
            </div>

            <div class="signup-form-container">
                <div class="signup-form">
                    <!-- Name -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="name.trim().length > 0">{{ $t('signup.form.name') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" v-model.trim="name" class="name" :class="{ filled: name.trim().length > 0, error: error.name }" :placeholder="$t('signup.form.name')" />
                        </div>
                    </div>

                    <!-- Surname -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="surname.trim().length > 0">{{ $t('signup.form.surnames') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" v-model.trim="surname" class="name" :class="{ filled: surname.trim().length > 0, error: error.surname }" :placeholder="$t('signup.form.surnames')" />
                        </div>
                    </div>

                    <!-- Email -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="email.trim().length > 0">{{ $t('signup.form.email') }}</div>
                        </div>
                        <div class="input">
                            <input type="email" v-model.trim="email" class="email" :class="{ filled: email.trim().length > 0, error: error.email }" :placeholder="$t('signup.form.email')" />
                        </div>
                        <div class="errorLabel" v-if="error.email">
                            <div class="value">
                                <div class="bold">{{ $t('signup.form.error.required_field') }}</div>
                                <div class="normal">{{ $t('signup.form.error.email') }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Phone -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="phone.trim().length > 0">{{ $t('signup.form.phone') }}</div>
                        </div>
                        <div class="input">
                            <input type="phone" v-model.trim="phone" class="phone" :class="{ filled: phone.trim().length > 0, error: error.phone }" :placeholder="$t('signup.form.phone')" />
                        </div>
                    </div>

                    <!-- Company -->
                    <div class="element">
                        <div class="label">
                            <div class="value" v-if="company.trim().length > 0">{{ $t('signup.form.company') }}</div>
                        </div>
                        <div class="input">
                            <input type="text" v-model.trim="company" class="company" :class="{ filled: company.trim().length > 0, error: error.company }" :placeholder="$t('signup.form.company')" />
                        </div>
                    </div>

                    <!-- Locals (Desktop) -->
                    <!-- <div class="element sites desktop">
                        <div class="label">
                            <div class="value">{{ $t('signup.form.sites.title') }}</div>
                        </div>
                        <div class="options">
                            <div class="option" @click="changeSitesOption('option1')" :class="{ selected: locals == 'option1', error: error.locals }">
                                {{ $t('signup.form.sites.option1') }}
                            </div>

                            <div class="option" @click="changeSitesOption('option2')" :class="{ selected: locals == 'option2', error: error.locals }">
                                {{ $t('signup.form.sites.option2') }}
                            </div>

                            <div class="option" @click="changeSitesOption('option3')" :class="{ selected: locals == 'option3', error: error.locals }">
                                {{ $t('signup.form.sites.option3') }}
                            </div>

                            <div class="option" @click="changeSitesOption('option4')" :class="{ selected: locals == 'option4', error: error.locals }">
                                {{ $t('signup.form.sites.option4') }}
                            </div>

                            <div class="option" @click="changeSitesOption('option5')" :class="{ selected: locals == 'option5', error: error.locals }">
                                {{ $t('signup.form.sites.option5') }}
                            </div>
                        </div>
                    </div> -->

                    <!-- Locals -->
                    <div class="element sites">
                        <div class="label">
                            <div class="value" v-if="locals.trim().length > 0">{{ $t('signup.form.sites.title') }}</div>
                        </div>
                        <div class="input select">
                            <select v-model="locals" class="company" :class="{ filled: locals.trim().length > 0, error: error.locals }">
                                <option value="" selected disabled>{{ $t('signup.form.sites.title') }}</option>
                                <option value="option1">{{ $t('signup.form.sites.option1') }}</option>
                                <option value="option2">{{ $t('signup.form.sites.option2') }}</option>
                                <option value="option3">{{ $t('signup.form.sites.option3') }}</option>
                                <option value="option4">{{ $t('signup.form.sites.option4') }}</option>
                                <option value="option5">{{ $t('signup.form.sites.option5') }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- Terms and conditions -->
                    <div class="element terms_and_conditions">
                        <div class="checkbox">
                            <input type="checkbox" v-model="terms_and_conditions" />
                        </div>
                        <div class="description">
                            <div class="part1" :class="{ error: error.terms_and_conditions }" @click="terms_and_conditions = !terms_and_conditions">{{ $t('signup.form.terms_and_conditions1') }}</div>
                            <div class="part2" :class="{ error: error.terms_and_conditions }">
                                <a target="_blank" :href="url_terms_and_conditions">{{ $t('signup.form.terms_and_conditions2') }}</a>
                                <span> *</span>
                            </div>
                        </div>
                    </div>

                    <!-- Send -->
                    <div class="element send">
                        <div v-if="validButton" class="send-button" @click="createLead()">
                            {{ $t('signup.form.save') }}
                        </div>
                        <div v-else class="send-button inactive">
                            {{ $t('signup.form.save') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="completed" v-else>
            <div class="row"></div>
            <div class="row greeting">
                <div class="thanks">{{ $t('signup.form.complete.title') }}</div>
                <div class="description1">{{ $t('signup.form.complete.description1') }}</div>
                <div class="andy"></div>
                <div class="description2">{{ $t('signup.form.complete.description2') }}</div>
                <div class="confirm">
                    <div class="confirm-bottom" @click="returnHomePage()">{{ $t('signup.form.complete.confirm') }}</div>
                </div>
            </div>
            <div class="row andy-row"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignUp',
    data() {
        return {
            language: '',
            languages: ['es', 'en', 'pt', 'de'],
            name: '',
            surname: '',
            email: '',
            phone: '',
            company: '',
            locals: '',
            terms_and_conditions: false,
            in_form: true,
            error: {
                name: false,
                surname: false,
                email: false,
                phone: false,
                company: false,
                locals: false,
                terms_and_conditions: false
            }
        }
    },
    computed: {
        url_terms_and_conditions() {
            let url = ''
            switch (this.language) {
                case 'es':
                    url = 'https://andyapp.io/terminos-legales/'
                    break
                case 'en':
                    url = 'https://andyapp.io/en/legal-terms/'
                    break
                case 'pt':
                    url = 'https://andyapp.io/terminos-legales/'
                    break
                case 'de':
                    url = 'https://andyapp.io/terminos-legales/'
                    break
            }
            return url
        },
        validButton() {
            let showErrors = false
            let validForm = this.validateForm(showErrors)
            return validForm
        }
    },
    methods: {
        returnHomePage() {
            let url = ''

            switch (this.language) {
                case 'es':
                    url = 'https://andyapp.io'
                    break
                case 'en':
                    url = 'https://andyapp.io/en'
                    break
                case 'pt':
                    url = 'https://andyapp.io'
                    break
                case 'de':
                    url = 'https://andyapp.io'
                    break
            }

            window.location.replace(url)
        },

        changeLang() {
            this.$store.commit('changeLanguage', this.language)
        },

        changeSitesOption(selectedOption) {
            this.locals = selectedOption
        },

        validateForm(showErrors = true) {
            let validate = true

            // Name
            if (this.name == '') {
                // this.error.name = true
                validate = false
            } else {
                this.error.name = false
            }

            // Surname
            if (this.surname == '') {
                // this.error.surname = true
                validate = false
            } else {
                this.error.surname = false
            }

            // Email
            if (this.email == '') {
                // this.error.email = true
                validate = false
            }

            if (showErrors && !validEmail(this.email)) {
                this.error.email = true
                validate = false
            } else {
                this.error.email = false
            }

            // Phone
            if (this.phone == '') {
                // this.error.phone = true
                validate = false
            } else {
                this.error.phone = false
            }

            // Company
            if (this.company == '') {
                // this.error.company = true
                validate = false
            } else {
                this.error.company = false
            }

            // Locals
            if (this.locals == '') {
                // this.error.locals = true
                validate = false
            } else {
                this.error.locals = false
            }

            // Terms and conditions
            if (!this.terms_and_conditions) {
                // this.error.terms_and_conditions = true
                validate = false
            } else {
                this.error.terms_and_conditions = false
            }

            return validate
        },

        createLead() {
            let showErrors = true

            if (this.validateForm(showErrors)) {
                let self = this
                let locals = ''
                let language = ''

                // prepare locals
                switch (this.locals) {
                    case 'option1':
                        locals = '1 - 9'
                        break
                    case 'option2':
                        locals = '11 - 49'
                        break
                    case 'option3':
                        locals = '50 - 199'
                        break
                    case 'option4':
                        locals = '201 - 499'
                        break
                    case 'option5':
                        locals = '+500'
                        break
                }

                // prepare lang
                switch (this.language) {
                    case 'es':
                        language = 'es_ES'
                        break
                    case 'en':
                        language = 'en_US'
                        break
                    case 'pt':
                        language = 'pt_PT'
                        break
                    case 'de':
                        language = 'de_DE'
                        break
                }

                // add lead
                this.$store
                    .dispatch('lead/addLead', {
                        name: this.name,
                        surname: this.surname,
                        email: this.email,
                        phone: this.phone,
                        company: this.company,
                        locals: locals,
                        lang: language
                    })
                    .then(function (response) {})

                // show next page
                this.in_form = false
            }
        }
    },
    created() {
        // set language
        let lang = typeof this.$route.params.lang !== 'undefined' ? this.$route.params.lang : 'es'

        // check if lang is not a valid language
        if (!this.languages.includes(lang)) {
            lang = 'es'
        }

        this.language = lang
    }
}
</script>

<style lang="scss" scoped>
.singup {
    height: 100%;

    .menu-top {
        @include display-flex();
        @include flex-direction(row);
        @include justify-content(space-between);
        @include align-items();
        @include background($color: #ffffff);
        height: 70px;
        width: 100%;
        box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.25);
        position: fixed;
        top: 0;
        padding: 0 150px;

        .logo {
            @include background($image: img('logo_andy.svg'), $size: contain, $position: center center);
            width: 140px;
            height: 50px;
            cursor: pointer;
            -webkit-backface-visibility: hidden;
        }

        .language {
            @include border-radius(3px);
            @include background($color: #ffffff, $image: img('down_caret_neutro_s90.svg'), $size: 8px 8px, $position: center right 10px);
            border: 1px solid $color-neutral-600;
            width: 62px;
            height: 43px;

            select {
                @include font-size(md);
                font-family: $text;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
                cursor: pointer;
                padding-left: 10px;
                text-transform: uppercase;
            }

            option {
                @include font-size(md);
                font-family: $text;
            }
        }
    }

    .content {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: calc(100%);
        width: 100%;
        padding-top: 70px;
        background: linear-gradient(284.46deg, #8dbacc 0%, #dcf4ed 100%);

        .information {
            display: grid;
            grid-gap: 0px;
            grid-template-columns: 100%;
            grid-template-rows: auto auto auto;
            align-items: start;
            align-content: center;
            justify-content: center;
            justify-items: center;
            width: 60%;
            height: 100%;
            padding-left: 60px;
            padding-right: 20px;

            .information-title {
                @include font-size(42px);
                font-family: $text-bold;
                color: $color-primary-500;
                text-align: center;
                margin-bottom: 16px;
                height: auto;
            }

            .information-description {
                @include font-size(xl);
                font-family: $text-medium;
                font-weight: normal;
                color: $color-black;
                text-align: center;
                height: auto;
            }

            .information-image {
                // signup_background
                @include background($image: img('signup_background.png'), $size: contain, $position: center center);
                height: 500px;
                width: 800px;
            }
        }

        .signup-form-container {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            @include background($image: img('ellipse_neutro.svg'), $size: 400px, $position: center right 20px);
            height: 100%;
            width: 40%;

            .signup-form {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                @include background($color: #ffffff);
                @include border-radius(4px);
                box-shadow: 0px 0px 10px rgba(111, 111, 111, 0.12);
                width: 400px;
                height: auto;
                margin: 0 auto;
                padding: 10px 0;

                .element {
                    height: auto;
                    min-height: 50px;
                    width: 100%;
                    margin: 0 0 10px 0;
                    padding: 0;

                    .label {
                        @include display-flex();
                        @include justify-content();
                        @include align-items(flex-start);
                        @include flex-wrap(wrap);
                        height: 20px;
                        width: 100%;
                        .value {
                            height: 100%;
                            width: 300px;
                            @include font-size(sm);
                            color: $color-neutral-300;
                        }
                    }

                    .input {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        @include border-radius(3px);
                        height: 40px;
                        width: 100%;
                        margin: 0;
                        padding: 0;

                        input {
                            @include font-size(md);
                            height: 100%;
                            width: 300px;
                            font-family: $text;
                            color: #000;
                            padding: 10px 0 10px 40px;
                            border: 1px solid $color-neutral-200;

                            &.name {
                                @include background($image: img('profile_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                                &.filled {
                                    @include background($image: img('profile_000000.svg'), $size: 20px, $position: left 10px center);
                                }
                            }

                            &.email {
                                @include background($image: img('mail_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                                &.filled {
                                    @include background($image: img('mail_000000.svg'), $size: 20px, $position: left 10px center);
                                }
                            }

                            &.phone {
                                @include background($image: img('phone_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                                &.filled {
                                    @include background($image: img('phone_000000.svg'), $size: 20px, $position: left 10px center);
                                }
                            }

                            &.company {
                                @include background($image: img('site_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                                &.filled {
                                    @include background($image: img('site_000000.svg'), $size: 20px, $position: left 10px center);
                                }
                            }

                            &.error {
                                border: 1px solid $color-error-500;
                            }

                            &:focus {
                                border: 1px solid $color-primary-500;
                            }

                            &::placeholder {
                                @include font-size(md);
                                font-family: $text;
                                color: $color-neutral-300;
                            }
                        }
                    }

                    .errorLabel {
                        @include display-flex();
                        @include justify-content();
                        @include align-items(flex-start);
                        @include flex-wrap(wrap);
                        width: 100%;

                        .value {
                            width: 300px;

                            .bold {
                                @include font-size(xs);
                                display: inline-block;
                                color: $color-error-800;
                                font-family: $text-bold;
                                margin-right: 2px;
                            }

                            .normal {
                                @include font-size(xs);
                                display: inline-block;
                                color: $color-error-800;
                                font-family: $text;
                            }
                        }
                    }

                    &.sites {
                        select {
                            @include font-size(md);
                            @include background($color: $color-neutral-200);
                            @include border-radius(3px);
                            height: 100%;
                            width: 300px;
                            font-family: $text;
                            color: $color-neutral-300;
                            padding: 10px 0 10px 40px;
                            border: 1px solid $color-neutral-200;

                            &.company {
                                @include background($image: img('location_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                                &.filled {
                                    @include background($image: img('location_000000.svg'), $size: 20px, $position: left 10px center);
                                }
                            }

                            &.error {
                                border: 1px solid $color-error-500;
                            }

                            &:focus {
                                border: 1px solid $color-primary-500;
                            }

                            option[value=''][disabled] {
                                display: none;
                            }

                            &.filled {
                                color: #000;
                            }
                        }
                    }

                    .options {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);

                        .option {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            @include background($color: $color-neutral-200);
                            @include border-radius(3px);
                            border: 1px solid $color-neutral-200;
                            height: 40px;
                            width: auto;
                            margin: 5px;
                            padding: 12px;
                            cursor: pointer;

                            &.selected {
                                @include background($color: $color-secondary-500);
                                color: #ffffff;

                                &.error {
                                    border: 1px solid $color-neutral-200;
                                }
                            }

                            &.error {
                                border: 1px solid $color-error-500;
                            }
                        }
                    }

                    // &.sites.desktop {
                    //     height: auto;
                    // }

                    // &.sites.mobile {
                    //     display: none;
                    // }

                    &.terms_and_conditions {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        margin: 0;

                        .checkbox {
                            width: auto;
                            margin-right: 6px;

                            input {
                                width: 15px;
                                height: 15px;
                                cursor: pointer;
                            }
                        }

                        .description {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            @include flex-wrap(wrap);

                            .part1 {
                                cursor: pointer;

                                &.error {
                                    color: $color-error-500;
                                }
                            }

                            .part2 {
                                margin-left: 5px;

                                a {
                                    text-decoration: underline;
                                    text-decoration-color: $color-primary-500;
                                }

                                &.error {
                                    color: $color-error-500;
                                }
                            }
                        }
                    }

                    &.send {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        .send-button {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            @include flex-wrap(wrap);
                            @include border-radius(3px);
                            @include font-size(ml);
                            width: 250px;
                            height: 40px;
                            background-color: $color-success-800;
                            color: #ffffff;
                            font-family: $text-bold;
                            cursor: pointer;

                            &.inactive {
                                background-color: $color-neutral-300;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }

    .completed {
        display: grid;
        grid-gap: 0px;
        grid-template-columns: 25% 50% 25%;
        grid-template-rows: 100%;
        align-items: start;
        align-content: center;
        justify-content: center;
        justify-items: center;
        height: 100%;
        background-color: #fff;

        background-image: url('../../public/img/ellipse_neutro.svg'), url('../../public/img/ellipse_second.svg');
        background-size: 400px, 400px;
        background-position: left 100px top calc(50% + 200px), right 50px bottom calc(50% + 30px);
        background-repeat: no-repeat, no-repeat;

        .row {
            width: 100%;
            height: 100%;
        }

        .greeting {
            display: grid;
            grid-gap: 0px;
            grid-template-columns: 100%;
            grid-template-rows: auto auto auto;
            align-items: start;
            align-content: center;
            justify-content: center;
            justify-items: center;
            height: 100%;
        }

        .thanks {
            @include font-size(64px);
            font-family: $text-bold;
            color: $color-primary-500;
            text-align: center;
            height: auto;
            width: 100%;
            margin-bottom: 25px;
        }

        .description1 {
            @include font-size(xl);
            font-family: $text-bold;
            color: $color-black;
            text-align: center;
            height: auto;
            width: 100%;
            margin-bottom: 25px;
        }

        .andy {
            display: none;
        }

        .description2 {
            @include font-size(ml);
            font-family: $text-medium;
            color: $color-black;
            text-align: center;
            height: auto;
            width: 100%;
            margin-bottom: 50px;
        }

        .confirm {
            .confirm-bottom {
                @include display-flex();
                @include justify-content();
                @include align-items();
                @include flex-wrap(wrap);
                @include border-radius(3px);
                @include font-size(md);
                width: 200px;
                height: 40px;
                background-color: $color-success-800;
                color: #ffffff;
                cursor: pointer;
            }
        }

        .andy-row {
            height: 100%;
            width: 100%;
            @include background($image: img('alex_cuerpo_08.svg'), $size: 300px, $position: left center);
        }
    }

    @media screen and (max-width: 960px) {
        .menu-top {
            padding: 0 20px;
        }

        .content {
            height: 100%;

            .information {
                width: 90%;
                height: auto;
                margin: 0;
                padding: 0;

                .information-title {
                    @include font-size(lg);
                    font-family: $text-bold;
                    color: $color-primary-500;
                    height: auto;
                    margin: 30px 0 12px 0;
                }

                .information-description {
                    @include font-size(md);
                    color: $color-black;
                    height: auto;
                    margin-bottom: 30px;
                }

                .information-image {
                    display: none;
                }
            }

            .signup-form-container {
                height: auto;
                width: 100%;
                padding-bottom: 40px;
                background: linear-gradient(284.46deg, #8dbacc 0%, #dcf4ed 100%);

                .signup-form {
                    width: 90%;
                    height: auto;

                    .element {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include flex-wrap(wrap);
                        // height: auto;
                        // margin: 20px 0;

                        .label,
                        .errorLabel {
                            width: 100%;

                            .value {
                                width: 90%;
                            }
                        }

                        .input {
                            width: 100%;

                            input {
                                width: 90%;
                            }
                        }

                        // &.sites.desktop {
                        //     display: none;
                        // }

                        // &.sites {
                        //     display: initial;

                        //     select {
                        //         @include font-size(md);
                        //         @include background($color: $color-neutral-200);
                        //         @include border-radius(3px);
                        //         height: 100%;
                        //         width: 90%;
                        //         font-family: $text;
                        //         color: #000000;
                        //         padding: 10px 0 10px 40px;
                        //         border: 1px solid $color-neutral-200;

                        //         &.company {
                        //             @include background($image: img('site_inactive_s30.svg'), $size: 20px, $position: left 10px center);

                        //             &.filled {
                        //                 @include background($image: img('site_000000.svg'), $size: 20px, $position: left 10px center);
                        //             }
                        //         }

                        //         &.error {
                        //             border: 1px solid $color-error-500;
                        //         }

                        //         &:focus {
                        //             border: 1px solid $color-primary-500;
                        //         }

                        //         option[value=''][disabled] {
                        //             display: none;
                        //         }
                        //     }
                        // }

                        &.terms_and_conditions {
                            .checkbox {
                                input {
                                    width: 18px;
                                    height: 18px;
                                }
                            }

                            .description {
                                .part1 {
                                }
                                .part2 {
                                }
                            }
                        }

                        &.send {
                            // position: fixed;
                            // bottom: 10px;
                            // margin: 0;
                            .send-button {
                                width: 81%;
                                max-width: 300px;
                            }
                        }
                    }
                }
            }
        }

        .completed {
            height: 100%;
            padding: 70px 0 0 0;
            grid-template-columns: 0% 100% 0%;

            background-image: url('../../public/img/ellipse_second.svg'), url('../../public/img/ellipse_neutro.svg');
            background-size: 200px, 200px;
            background-position: left -80px top calc(50% + 50px), right -80px bottom calc(50% + 100px);
            background-repeat: no-repeat, no-repeat;

            .thanks {
                @include font-size(xl);
                margin: 0 0 12px 0;
            }

            .andy-row {
                background-image: none;
            }

            .description1 {
                @include font-size(md);
                font-family: $text-medium;
                margin-bottom: 20px;
            }

            .andy {
                @include background($image: img('alex_cuerpo_08.svg'), $size: contain, $position: center center);
                display: inherit;
                height: 250px;
                width: 100%;
            }

            .description2 {
                margin: 20px 0 0 0;
            }

            .confirm {
                margin: 10px 0 0 0;
                // position: fixed;
                // bottom: 10px;
                width: 100%;
                .confirm-bottom {
                    @include display-flex();
                    @include justify-content();
                    @include align-items();
                    @include flex-wrap(wrap);
                    @include font-size(ml);
                    margin: 0 auto;
                    width: 80%;
                    font-family: $text-bold;
                    max-width: 300px;
                }
            }
        }
    }

    @media screen and (max-width: 400px) {
        .terms_and_conditions {
            .checkbox {
                margin: 0;
                display: inline-block;
                width: 20%;
            }

            .description {
                display: inline-block;
                width: 80%;
                padding-left: 5px;
                .part1 {
                    width: 100%;
                }
                .part2 {
                    width: 100%;
                    margin-left: 0 !important;
                }
            }
        }
    }
}
</style>
